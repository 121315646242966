<template>
  <v-btn icon v-on:click="signOut()">
    <v-icon>mdi-logout-variant</v-icon>
  </v-btn>
</template>

<script>
import Auth from '@aws-amplify/auth'

export default {
  name: 'SignOut',
  methods: {
    signOut () {
      Auth.signOut().then(() => {
        this.authState = { loading: false, authenticated: false, user: null }
      }).catch(e => {
        console.error(e)
      })
    }
  }
}
</script>
