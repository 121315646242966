<template>
  <v-app-bar app>
    <v-app-bar-nav-icon @click="setDrawer(!drawer)"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <SignOut></SignOut>
  </v-app-bar>
</template>

<script>
import SignOut from '@/components/SignOut.vue'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'layout-complex-topnav',
  components: {
    SignOut
  },
  computed: {
    ...mapState(['drawer'])
  },
  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER'
    })
  }
}
</script>
