<template>
  <v-main>
    <v-container fluid>
      <router-view></router-view>
<!--      <layout-complex-footer></layout-complex-footer>-->
    </v-container>
  </v-main>
</template>

<script>
// import LayoutComplexFooter from '@/layouts/complex/Footer'

export default {
  name: 'layout-complex-view',
  // components: { LayoutComplexFooter }
}
</script>
