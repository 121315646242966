<template>
  <v-navigation-drawer
      v-model="drawer"
      app
      dark
  >
    <v-list-item>
      <v-list-item-avatar>
        <v-icon
            class="accent"
            dark
            v-on:mouseover="changeIcon('mdi-egg-easter')"
            v-on:mouseleave="changeIcon('mdi-egg')"
        >
          {{ theEgg }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="title text-uppercase font-weight-bold">
          Dotty
        </v-list-item-title>
        <v-list-item-subtitle>
          Selbstauskünftei
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.to"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'layout-complex-sidebar',
  data() {
    return {
      items: [
        {
          title: 'Dashboard',
          icon: 'mdi-view-dashboard',
          to: '/'
        },
        {
          title: 'Selbstauskunft',
          icon: 'mdi-file-document-multiple',
          to: '/disclosure'
        }
      ],
      right: null,
      theEgg: 'mdi-egg'
    };
  },
  methods: {
    changeIcon: function(icon)  {
      this.theEgg = icon;
    }
  },
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val);
      }
    }
  }
};
</script>

<style lang="scss">
.theme--dark.v-navigation-drawer {
  background-color: #282d32;
}

.v-application--is-ltr {
  .v-list-item__action:first-child,
  .v-list-item__icon:first-child {
    margin-right: 1em;
  }
}
</style>
