<template>
  <v-app>
    <layout-complex-sidebar></layout-complex-sidebar>
    <layout-complex-topnav></layout-complex-topnav>
    <layout-complex-view></layout-complex-view>
  </v-app>
</template>

<script>
import LayoutComplexSidebar from '@/layouts/complex/Sidebar'
import LayoutComplexTopnav from '@/layouts/complex/TopNav'
import LayoutComplexView from '@/layouts/complex/View'

export default {
  components: {
    LayoutComplexSidebar,
    LayoutComplexTopnav,
    LayoutComplexView
  }
}
</script>
